<template>
  <div>
    <SfModal
      v-e2e="'login-modal'"
      :visible="isLoginModalOpen"
      class="modal"
      @close="closeModal"
    >
      <template #modal-bar>
        <SfBar
          class="sf-modal__bar smartphone-only"
          :close="true"
          :title="$t(barTitle)"
          @click:close="closeModal"
        />
      </template>
      <transition
        name="sf-fade"
        mode="out-in"
      >
        <div v-if="isLogin">
          <ValidationObserver
            v-slot="{ handleSubmit }"
            key="log-in"
          >
            <form
              class="form"
              @submit.prevent="handleSubmit(handleLogin)"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|email"
              >
                <SfInput
                  v-model="form.username"
                  v-e2e="'login-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :label="$t('Your email')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|password|withoutSpaces"
              >
                <SfInput
                  v-model="form.password"
                  v-e2e="'login-modal-password'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="password"
                  :label="$t('Password')"
                  type="password"
                  has-show-password
                  class="form__element"
                />
              </ValidationProvider>
              <recaptcha v-if="isRecaptchaEnabled" />
              <div v-if="error.login">
                {{ $t(error.login) }}
              </div>
              <SfButton
                v-e2e="'login-modal-submit'"
                type="submit"
                class="sf-button--full-width form__button"
                :disabled="loading"
              >
                <SfLoader
                  :class="{ loader: loading }"
                  :loading="loading"
                >
                  <div>{{ $t('Login') }}</div>
                </SfLoader>
              </SfButton>
            </form>
          </ValidationObserver>
          <div class="action">
            <SfButton
              class="sf-button--text"
              @click="setIsForgottenValue(true)"
            >
              {{ $t('Forgotten password?') }}
            </SfButton>
          </div>
          <div class="bottom">
            <p class="bottom__paragraph">
              {{ $t('No account') }}
            </p>
            <SfButton
              class="sf-button--text"
              @click="setIsLoginValue(false)"
            >
              {{ $t('Register here') }}
            </SfButton>
          </div>
        </div>
        <div v-else-if="isForgotten">
          <p>{{ $t('Forgot Password') }}</p>
          <ValidationObserver
            v-slot="{ handleSubmit }"
            key="log-in"
          >
            <form
              class="form"
              @submit.prevent="handleSubmit(handleForgotten)"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|email"
              >
                <SfInput
                  v-model="form.username"
                  v-e2e="'forgot-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="forgot-email"
                  :label="$t('Forgot Password Modal Email')"
                  class="form__element"
                />
              </ValidationProvider>
              <recaptcha v-if="isRecaptchaEnabled" />
              <div
                v-if="forgotPasswordError.request"
                class="thank-you"
              >
                <p class="thank-you__paragraph">
                  {{
                    $t(
                      'It was not possible to request a new password, please check the entered email address.'
                    )
                  }}
                </p>
              </div>
              <SfButton
                v-e2e="'forgot-modal-submit'"
                type="submit"
                class="sf-button--full-width form__button"
                :disabled="forgotPasswordLoading"
              >
                <SfLoader
                  :class="{ loader: forgotPasswordLoading }"
                  :loading="forgotPasswordLoading"
                >
                  <div>{{ $t('Reset Password') }}</div>
                </SfLoader>
              </SfButton>
            </form>
          </ValidationObserver>
        </div>
        <div
          v-else-if="isThankYouAfterForgotten"
          class="thank-you"
        >
          <i18n
            tag="p"
            class="thank-you__paragraph"
            path="forgotPasswordConfirmation"
          >
            <span class="thank-you__paragraph--bold">{{ userEmail }}</span>
          </i18n>
          <p class="thank-you__paragraph">
            {{ $t('Thank You Inbox') }}
          </p>
        </div>
        <div
          v-else
          class="form"
        >
          <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            key="sign-up"
          >
            <form
              class="form"
              autocomplete="off"
              @submit.prevent="handleSubmit(handleRegister)"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|email|withoutSemicolon"
              >
                <SfInput
                  v-model="form.email"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :label="$t('Your email')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|withoutSemicolon"
              >
                <SfInput
                  v-model="form.firstName"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="first-name"
                  :label="$t('First Name')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|withoutSemicolon"
              >
                <SfInput
                  v-model="form.lastName"
                  v-e2e="'login-modal-lastName'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="last-name"
                  :label="$t('Last Name')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|password|withoutSpaces"
                vid="password"
              >
                <SfInput
                  v-model="form.password"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="password"
                  :label="$t('Password')"
                  type="password"
                  has-show-password
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|confirmed:password"
              >
                <SfInput
                  v-model="form.repassword"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="re-password"
                  :label="$t('Re-Password')"
                  type="password"
                  has-show-password
                  class="form__element repassword"
                />
              </ValidationProvider>

              <SfCheckbox
                v-model="isSubscribed"
                v-e2e="'sign-up-newsletter'"
                :label="$t('Sign Up for Newsletter')"
                name="signupNewsletter"
                class="form__element"
              />
              <ValidationProvider
                v-slot="{ errors }"
                :rules="{ required: { allowFalse: false } }"
              >
                <SfCheckbox
                  v-model="createAccount"
                  v-e2e="'login-modal-create-account'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="create-account"
                  :label="$t('I want to create an account')"
                  class="form__element"
                />
              </ValidationProvider>
              <recaptcha v-if="isRecaptchaEnabled" />
              <div v-if="error.register">
                {{ $t(error.register) }}
              </div>
              <SfButton
                v-e2e="'login-modal-submit'"
                type="submit"
                class="sf-button--full-width form__button"
                :disabled="loading || !createAccount || invalid"
              >
                <SfLoader
                  :class="{ loader: loading }"
                  :loading="loading"
                >
                  <div>{{ $t('Create an account') }}</div>
                </SfLoader>
              </SfButton>
            </form>
          </ValidationObserver>
          <div class="action">
            {{ $t('or') }}
            <SfButton
              v-e2e="'login-modal-login-to-your-account'"
              class="sf-button--text"
              @click="setIsLoginValue(true)"
            >
              {{ $t('login in to your account') }}
            </SfButton>
          </div>
        </div>
      </transition>
    </SfModal>
    <CustomerGtm
      v-if="hasLoggedIn"
      :is-logged="isLoggedIn"
      :customer-data="{ user }"
    />
  </div>
</template>
<script>
import {
  ref,
  watch,
  reactive,
  defineComponent,
  computed,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  SfModal,
  SfInput,
  SfButton,
  SfCheckbox,
  SfLoader,
  SfBar,
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
import {
  useUser,
  useForgotPassword,
} from '@vue-storefront/gemini';
import { useUiState, useWishlist } from '~/composables';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import CustomerGtm from '~/components/Gtm/CustomerGtm.vue';
import { removeItem } from '~/helpers/asyncLocalStorage';

extend('withoutSpaces', {
  message: 'The field must not contain whitespaces',
  validate: (value) => !value.includes(' '),
});

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('confirmed', {
  ...confirmed,
  message: 'Please make sure your passwords match',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});
extend('withoutSemicolon', {
  message: 'This field cannot contain a semicolon (;)',
  validate: (string) => !string.includes(';'),
});

extend('withoutSemicolon', {
  message: 'This field cannot contain a semicolon (;)',
  validate: (string) => !string.includes(';'),
});

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
    SfBar,
    CustomerGtm,
  },
  setup() {
    const { isLoginModalOpen, toggleLoginModal, goToForgotPassword } = useUiState();
    const isSubscribed = ref(true);
    const form = ref({});
    const isLogin = ref(true);
    const createAccount = ref(false);
    const rememberMe = ref(false);
    const isForgotten = ref(false);
    const isThankYouAfterForgotten = ref(false);
    const userEmail = ref('');
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha,
    );
    const { app } = useContext();
    const router = useRouter();

    const {
      register, login, loading, user, error: userError,
    } = useUser();
    const { loadWishlist } = useWishlist();
    const {
      request,
      error: forgotPasswordError,
      loading: forgotPasswordLoading,
    } = useForgotPassword();
    const isLoggedIn = ref(false);

    const barTitle = computed(() => {
      if (isLogin.value) {
        return 'Sign in';
      }
      if (isForgotten.value || isThankYouAfterForgotten.value) {
        return 'Reset Password';
      }
      return 'Register';
    });

    const error = reactive({
      login: null,
      register: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.register = null;
    };

    watch(isLoginModalOpen, () => {
      if (isLoginModalOpen) {
        form.value = {};
        resetErrorValues();
      }

      if (isSubscribed.value === false) {
        isSubscribed.value = true;
      }
    });

    const setIsLoginValue = (value) => {
      resetErrorValues();
      isLogin.value = value;
    };

    const setIsForgottenValue = (value) => {
      resetErrorValues();
      isForgotten.value = value;
      isLogin.value = !value;
    };

    const closeModal = () => {
      setIsForgottenValue(false);
      setIsLoginValue(true);
      toggleLoginModal();
    };

    const handleForm = (fn) => async () => {
      resetErrorValues();

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();
        form.value.recaptchaInstance = $recaptcha;

        await fn({
          user: {
            ...form.value,
            is_subscribed: isSubscribed.value,
            recaptchaToken,
          },
        });
      } else {
        await fn({
          user: {
            ...form.value,
            is_subscribed: isSubscribed.value,
          },
        });
      }

      const hasUserErrors = userError.value.register || userError.value.login;
      if (hasUserErrors) {
        error.login = userError.value.login?.message;
        error.register = userError.value.register?.message;
        return;
      }
      isLoggedIn.value = true;
      setIsLoginValue(true);
      await toggleLoginModal();
      await removeItem('checkout');

      if (isRecaptchaEnabled.value) {
        // reset recaptcha
        $recaptcha.reset();
      }
      if (fn === login) {
        app.$gtm.push({
          event: 'login',
        });
      }
      if (fn === register) {
        app.$gtm.push({
          event: 'sign_up',
        });
      }

      router.push(app.localePath('/my-account'));
    };

    const hasLoggedIn = computed(() => {
      if (isLoggedIn && isLoggedIn.value) {
        return isLoggedIn.value;
      }
      return false;
    });

    watch(isLoggedIn, () => {
      if (isLoggedIn.value) {
        hasLoggedIn.value = true;
        return hasLoggedIn.value;
      }
      return false;
    });

    const handleRegister = async () => handleForm(register)();

    const handleLogin = async () => {
      await handleForm(login)();
      await loadWishlist();
    };

    const handleForgotten = async () => {
      userEmail.value = form.value.username;

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();

        await request({ email: userEmail.value, recaptchaToken });
      } else {
        await request({ email: userEmail.value });
      }

      if (!forgotPasswordError.value.request) {
        isThankYouAfterForgotten.value = true;
        isForgotten.value = false;
      }

      if (isRecaptchaEnabled.value) {
        // reset recaptcha
        $recaptcha.reset();
      }
    };

    watch(goToForgotPassword, () => setIsForgottenValue(goToForgotPassword.value));

    return {
      barTitle,
      closeModal,
      createAccount,
      error,
      forgotPasswordError,
      forgotPasswordLoading,
      form,
      handleForgotten,
      handleLogin,
      handleRegister,
      isForgotten,
      isLogin,
      isLoginModalOpen,
      isSubscribed,
      isThankYouAfterForgotten,
      loading,
      rememberMe,
      setIsForgottenValue,
      setIsLoginValue,
      userEmail,
      userError,
      hasLoggedIn,
      isLoggedIn,
      isRecaptchaEnabled,
      user,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  --modal-index: 50;
  --overlay-z-index: 49;

  .sf-modal__container {
    z-index: 99;
  }
}

.form {
  margin-top: var(--spacer-sm);

  &__element {
    margin: 0 0 var(--spacer-xl) 0;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
  font: var(--font-weight--light) var(--font-size--base) / 1.6
    var(--font-family--secondary);

  & > * {
    margin: 0 0 0 var(--spacer-xs);
  }
}

.action {
  margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
}

.checkbox {
  margin-bottom: var(--spacer-2xl);
}

.bottom {
  text-align: center;
  margin-bottom: var(--spacer-lg);
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight--semibold);
  font-family: var(--font-family--secondary);

  &__paragraph {
    color: var(--c-primary);
    margin: 0 0 var(--spacer-base) 0;
    @include for-desktop {
      margin: 0;
    }
  }
}

.thank-you {
  &__paragraph {
    &--bold {
      font-weight: var(--font-weight--semibold);
    }
  }
}
</style>

<style lang="scss">
.sf-modal__container {
  z-index: 99 !important;

  input,
  select {
    box-shadow: none;
    outline: none;

    &:active,
    &:focus,
    &:focus-visible {
      box-shadow: none;
      outline: none;
    }
  }

  .form {
    button[type='submit'] {
      font-family: 'Spartan';
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #fdcfc1;

      &:hover {
        background-color: #606662;
        border-color: #606662;
      }
      &.is-disabled--button {
        color: #e0e0e1;
      }
    }

    label.sf-input__label {
      color: #5e6770;
      font-family: 'Spartan';
      font-size: var(--font-size--sm);
    }

    .sf-checkbox__label {
      font-family: 'Spartan';
      font-size: var(--font-size--sm);
    }

    .sf-input__wrapper {
      label {
        // font-family: 'Spartan';
      }
    }
  }

  .action *,
  .action {
    font-family: 'Spartan';
  }

  .bottom * {
    font-family: 'Spartan';
  }

  .bottom p {
    font-size: var(--font-size--base);
  }

  .sf-bar {
    background-color: #222a24;

    .sf-icon {
      fill: #fff;
    }
  }

  .sf-bar__title {
    color: #fff;
    font-family: 'Spartan';
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
.form__element.repassword {
  margin-bottom: 0;
}
</style>
